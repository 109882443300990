import http from "@/http";
import eventBus from "@/events";

const Account = {
    findAll: async function (ctx) {
        if(ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sortDesc = ctx.sortDesc ? ',desc' : '';
            const sort = (ctx.sortBy || 'name') + sortDesc;
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const resp = await http("/account/search/all", {params});
            eventBus.$emit('LIST_COUNT_ACCOUNT_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.accounts;
        } else {
            const resp = await http("/account/search/all");
            eventBus.$emit('LIST_COUNT_ACCOUNT_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.accounts;
        }
    },
    create: function(data) {
        return http.post('/account', data);
    },
    update: function (id, data) {
        return http.patch('/account/' + id, data);
    },
    findById(id) {
        return http.get('/account/' + id);
    },
    enable(id) {
        return http.patch('/account/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/account/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/account/' + id)
    },
    findCompany: function(id) { return http.get('undefined'.replace('{id}', id)); },codeExists(code) {
        return http.get("/account/exists/code", {params: {code: code}}).then(x => {
            return !x.data;
        });
    }
};

export default Account;
